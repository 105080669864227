import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import useWindowSize from '../../../hooks/common/useWindowResize';
import MuiTableRow from './MuiTableRow';
import MuiTableHeader from './MuiTableHeader';
import InfiniteScroll from 'react-infinite-scroll-component';

const Table = (
  {
    data = [],
    columns = [],
    childData = [],
    childColumns = [],
    hasChild = false,
    expandAll = false,
    showChildHeader = true,
    childStyles = {},
    rowModelType = 'clientSide', // if `infinite` it will use infinite scroll functions
    datasource = () => null,
    height = null,
    initialRowCount = 10,
    infiniteInitialRowCount = 1,
    filterProps = {},
  },
  ref,
) => {
  const theme = useTheme();

  const tableEl = useRef();
  const tableRef = useRef(ref);

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [rows, setRows] = useState([]);
  const [tableContainer, setTableContainer] = useState(null);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [order, setOrder] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [filterModel, setFilterModel] = useState({});

  const [pinnedColumns, setPinnedColumns] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    setTableContainer(document?.getElementById('table-container'));
  }, [windowWidth, windowHeight]);

  // const scrollListener = useCallback(() => {
  //   if (rowModelType === 'infinite' && hasMore) {
  //     let bottom = tableEl?.current.scrollHeight - tableEl?.current.clientHeight;

  //     if (!distanceBottom) {
  //       setDistanceBottom(Math.round(bottom * 0.2));
  //     }
  //     if (tableEl?.current.scrollTop > bottom - distanceBottom) {
  //       console.log('here');
  //       setIsLoading(true);
  //       const amount = rows.length + initialRowCount;
  //       datasource(amount, orderBy, order)
  //         .then(res => {
  //           if (res === -1) setHasMore(false);
  //           else setHasMore(true);
  //         })
  //         .finally(() => setIsLoading(false));
  //     }
  //   }
  // }, [rowModelType, datasource, distanceBottom, orderBy, order]);

  // useLayoutEffect(() => {
  //   if (rowModelType === 'infinite') {
  //     if (tableEl?.current?.clientHeight >= tableRef?.current?.clientHeight && hasMore) {
  //       setIsLoading(true);
  //       const amount = rows.length + initialRowCount;
  //       datasource(amount, orderBy, order)
  //         .then(res => {
  //           if (res === -1) setHasMore(false);
  //           else setHasMore(true);
  //         })
  //         .finally(() => setIsLoading(false));
  //     }
  //   }
  // }, [rowModelType])

  const onRequestSort = (colId, direction) => {
    if (datasource) {
      if (rowModelType === 'infinite') {
        setRows([]);
        // console.log('######: ', colId, direction);
        datasource(initialRowCount, direction && colId, direction)
          .then(res => {
            if (res === -1) setHasMore(false);
            else setHasMore(true);
          })
          .finally(() => setIsLoading(false));
      }
      if (direction) {
        setOrderBy(colId);
        setOrder(direction);
      } else {
        setOrder(null);
        setOrderBy(null);
      }
    }
  };

  const onRequestFilterChange = (colId, model) => {
    // const newModel =
    if (rowModelType === 'infinite' && datasource) {
      setFilterModel(prev => {
        const newModel = { ...prev };
        if (model === 'ALL') {
          delete newModel[colId];
        } else {
          newModel[colId] = model;
        }
        console.log(newModel);
        datasource(initialRowCount, colId, null, newModel)
          .then(res => {
            if (res === -1) setHasMore(false);
            else setHasMore(true);
          })
          .finally(() => setIsLoading(false));
        return newModel;
      });
    }
  };

  const onRequestPinColumn = colId => {
    setPinnedColumns(prev =>
      prev?.find(x => x === colId) ? prev.filter(x => x !== colId) : [...prev, colId],
    );
  };

  const ColumnHeaders = useMemo(() => {
    const newColumns = columns
      .filter(x => !x.hide)
      .map(x => (pinnedColumns.includes(x.id) ? { ...x, pinned: true } : { ...x, pinned: false }));

    return (
      <TableHead>
        <TableRow>
          <TableCell
            className="sticky"
            sx={{
              position: 'sticky',
              background: theme.palette.white,
              left: 0,
              top: 0,
              zIndex: 20,
              padding: '0 !important',
            }}
          >
            {hasChild && (
              <TableCell
                component={Box}
                sx={{
                  minWidth: '60px',
                  width: '60px',
                  maxWidth: '60px',
                  height: '58px',
                  background: theme.palette.white,
                }}
              />
            )}
            {newColumns
              ?.filter(x => x.pinned)
              .map(column => (
                <MuiTableHeader
                  key={column.id}
                  component={Box}
                  column={column}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={onRequestSort}
                  onRequestPinColumn={onRequestPinColumn}
                  filterProps={filterProps}
                  filterModel={filterModel}
                  onRequestFilterChange={onRequestFilterChange}
                />
              ))}
          </TableCell>
          {newColumns
            ?.filter(x => !x.pinned)
            .map(column => (
              <MuiTableHeader
                key={column.id}
                column={column}
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                onRequestPinColumn={onRequestPinColumn}
                filterProps={filterProps[column.id]}
                filterModel={filterModel}
                onRequestFilterChange={onRequestFilterChange}
              />
            ))}
        </TableRow>
      </TableHead>
    );
  }, [columns, order, orderBy, pinnedColumns, filterProps, filterModel]);

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: 0,
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          height: hasChild
            ? 'auto'
            : `calc(((${Math.min(data?.length, 10) ?? 0} + 1) * 3.6875rem) + ${tableContainer && tableContainer.scrollWidth > tableContainer.clientWidth ? '48px' : '12px'})`,
          width: '100%',
          margin: 0,
          padding: 0,
          position: 'relative',
          borderRadius: 0,

          '&::after': {
            content:
              tableContainer && tableContainer.scrollWidth > tableContainer.clientWidth
                ? '""'
                : null,
            position: 'absolute',
            width: 'calc(100% - 32px)',
            height: '24px',
            bottom: '12px',
            left: '0',
            backgroundColor: theme.palette.white,
            zIndex: 10,
            borderTop:
              tableContainer && tableContainer.scrollHeight > tableContainer.clientHeight
                ? `1px solid ${theme.palette.text['500']}`
                : 'none',
          },
          '&::before': {
            content:
              tableContainer && tableContainer.scrollHeight > tableContainer.clientHeight
                ? '""'
                : null,
            position: 'absolute',
            height: `calc(100% - ${tableContainer && tableContainer.scrollWidth > tableContainer.clientWidth ? '34px' : '0px'})`,
            width: '20px',
            top: 0,
            right: '12px',
            backgroundColor: theme.palette.white,
            zIndex: 10,
            borderLeft: `1px solid ${theme.palette.text['500']}`,
          },
        }}
      >
        {tableContainer &&
          tableContainer.scrollWidth > tableContainer.clientWidth &&
          tableContainer.scrollHeight > tableContainer.clientHeight && (
            <Box
              sx={{
                position: 'absolute',
                width: '20px',
                height: '24px',
                background: '#FFFFFF',
                bottom: '12px',
                right: '12px',
                zIndex: '10',
              }}
            />
          )}
        <TableContainer
          id="table-container"
          component={Paper}
          ref={tableEl}
          sx={{
            width: '100%',
            height: height ?? '100%',
            overflow: 'auto',
            borderLeft: `1px solid ${theme.palette.text['500']}`,
            paddingBottom:
              tableContainer && tableContainer.scrollWidth > tableContainer.clientWidth
                ? '24px'
                : '0px',
            paddingRight:
              tableContainer && tableContainer.scrollHeight > tableContainer.clientHeight
                ? '20px'
                : '0px',
            borderRadius: 0,
            boxShadow: 'none',
          }}
          className="table-scroller"
        >
          <MuiTable
            ref={tableRef}
            stickyHeader
            sx={{
              borderLeft: `1px solid ${theme.palette.text['500']}`,
              '& .MuiTableHead-root': {
                '& .MuiTableRow-root': {
                  height: '61px',
                  '& .MuiTableCell-root': {
                    backgroundColor: theme.palette.text['600'],
                    borderTop: `1px solid ${theme.palette.text['500']}`,
                    borderRight: `1px solid ${theme.palette.text['500']}`,
                    borderBottom: `1px solid ${theme.palette.text['500']}`,
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '14px',
                    padding: '17px 15px',
                  },
                },
              },

              '& .MuiTableBody-root': {
                '& .MuiTableRow-root': {
                  '& .MuiTableCell-root': {
                    borderRight: `1px solid ${theme.palette.text['500']}`,
                    borderBottom: `1px solid ${theme.palette.text['500']}`,

                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontSize: '14px',
                    padding: '10px 15px',
                  },
                },
              },
            }}
            aria-label="collapsible table"
          >
            <InfiniteScroll
              dataLength={rows.length}
              next={() => {
                if (datasource) {
                  const amount = rows.length + initialRowCount;
                  datasource(amount, orderBy, order)
                    .then(res => {
                      if (res === -1) setHasMore(false);
                      else setHasMore(true);
                    })
                    .finally(() => setIsLoading(false));
                }
              }}
              hasMore={hasMore}
              loader={() => {
                return isLoading
                  ? Array.from(Array(infiniteInitialRowCount).keys()).map(x => (
                      <MuiTableRow
                        key={x}
                        columns={columns}
                        hasChild={hasChild}
                        childData={childData}
                        childColumns={childColumns}
                        expandAll={expandAll}
                        showChildHeader={showChildHeader}
                        pinnedColumns={pinnedColumns}
                        childStyles={childStyles}
                        isLoading={true}
                      />
                    ))
                  : null;
              }}
              style={{ overflow: 'unset' }}
              scrollableTarget="table-container"
            >
              {ColumnHeaders}
              <TableBody>
                {!rows || rows.length === 0 ? (
                  isLoading ? (
                    Array.from(Array(initialRowCount).keys()).map(x => (
                      <MuiTableRow
                        key={x}
                        columns={columns}
                        hasChild={hasChild}
                        childData={childData}
                        childColumns={childColumns}
                        expandAll={expandAll}
                        showChildHeader={showChildHeader}
                        childStyles={childStyles}
                        isLoading={true}
                      />
                    ))
                  ) : (
                    <div
                      style={{
                        position: 'fixed',
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '150px',
                        paddingRight: '100px',
                        fontFamily: 'Poppins',
                      }}
                    >
                      Data Not Found
                    </div>
                  )
                ) : null}
                {rows?.map(row => (
                  <MuiTableRow
                    key={row.id}
                    row={row}
                    columns={columns}
                    hasChild={hasChild}
                    childData={childData}
                    childColumns={childColumns}
                    expandAll={expandAll}
                    showChildHeader={showChildHeader}
                    pinnedColumns={pinnedColumns}
                    childStyles={childStyles} // Pass childStyles prop to MuiTableRow
                  />
                ))}
                {isLoading
                  ? Array.from(Array(infiniteInitialRowCount).keys()).map(x => (
                      <MuiTableRow
                        key={x}
                        columns={columns}
                        hasChild={hasChild}
                        childData={childData}
                        childColumns={childColumns}
                        expandAll={expandAll}
                        showChildHeader={showChildHeader}
                        pinnedColumns={pinnedColumns}
                        childStyles={childStyles}
                        isLoading={true}
                      />
                    ))
                  : null}
              </TableBody>
            </InfiniteScroll>
          </MuiTable>
          {/* </InfiniteScroll> */}
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default forwardRef(Table);
